/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.14.1-v202504031941-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqGraphQLApiClass {
 
   constructor() {}

  /**
   * @summary Process a GraphQL operation
   */
  public graphql(
    body: models.GraphQLInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/graphql`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GraphQLOutputV1>;
  }

}


export const sqGraphQLApi = new sqGraphQLApiClass();
