import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import _ from 'lodash';
import { InputGroup } from '@seeqdev/qomponents';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import {
  getFormFieldProps,
  getFormFieldWrapperProps,
  getValidationFunction,
} from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';

export interface InputWithLinkedButtonsProps extends ValidatingFormComponent<string> {
  component: 'InputWithLinkedButtonsFormComponent';
  customErrorText?: string;
  placeholder?: string;
  removeAction: () => void;
  addButtonAction: () => void;
  hideTrashButton?: boolean;
  includeAddButton?: boolean;
  addButtonTooltip?: string;
  maxLength?: number;
  extraClassNames?: string;
  showLabelAfterInputs?: boolean;
  labelAfterInputs?: string;
  wrapperClassNames?: string;
}

export const InputWithLinkedButtonsFormComponent: React.FunctionComponent<InputWithLinkedButtonsProps> = (props) => {
  const {
    name,
    testId = 'inputWithLinkedButtons',
    onChange,
    customErrorText,
    placeholder = '',
    removeAction,
    addButtonAction,
    hideTrashButton,
    includeAddButton,
    addButtonTooltip = '',
    maxLength,
    required,
    extraClassNames = '',
    showLabelAfterInputs = false,
    labelAfterInputs = '',
    wrapperClassNames,
  } = props;

  const { t } = useTranslation();
  const defaultValidation = (value: string | undefined) =>
    (required && _.isEmpty(_.trim(value))) || !!(maxLength && value && value.length > maxLength);
  const appliedValidation = getValidationFunction(defaultValidation, props.extendValidation, props.validation);

  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    (_.has(formState.dirtyFields, name) || _.has(formState.dirtyFieldsSinceLastSubmit, name)) &&
    formState.hasValidationErrors;

  const appendButtons = [
    !hideTrashButton && {
      variant: 'button',
      buttonProps: {
        icon: 'fa-trash',
        onClick: removeAction,
        iconStyle: 'theme',
      },
    },
    includeAddButton && {
      variant: 'button',
      buttonProps: {
        icon: 'fa-plus',
        onClick: addButtonAction,
        tooltip: t(addButtonTooltip),
        variant: 'outline',
      },
    },
  ];

  return (
    <>
      <FormFieldWrapper
        {...getFormFieldWrapperProps(props, ['id'])}
        wrapperClassNames={`flexFill ${wrapperClassNames}`}
        showError={showError}
        customErrorText={customErrorText}>
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const properProps = _.assign({}, getFormFieldProps(formState, input, meta, props), {
              placeholder: t(placeholder),
              value: input.value,
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                input.onChange(e.target.value);
                onChange?.(e.target.value);
              },
            });
            return (
              <div className="inputContainer">
                <InputGroup
                  {...properProps}
                  type="text"
                  extraClassNames={`width-maximum ${extraClassNames}`}
                  maxLength={maxLength}
                  testId={`${testId}-input`}
                  required={showError && required}
                  append={appendButtons.filter(Boolean)}
                />

                {showLabelAfterInputs && (
                  <div className="rightAlignedLabel addInputGroupLabel">{t(labelAfterInputs)}</div>
                )}
              </div>
            );
          }}
        </Field>
      </FormFieldWrapper>
    </>
  );
};
